<template>
  <div class="modal in">
    <div class="modal__body">

      <div class="modal__body__loading">Загрузка...</div>
      <div class="modal__body__close" @click="onCloseClick">X</div>

<!--      <iframe class="airtable-embed" src="https://airtable.com/embed/shrZlKdg3179WhmnT?backgroundColor=yellow"
              frameborder="0"
              onmousewheel=""
              width="100%"
              height="533"
              style="background: transparent; border: 1px solid #ccc;">Loading...
      </iframe>-->


      <iframe class="airtable-embed"
              :src="'https://airtable.com/embed/shrFQmHEJUya3b12o?prefill_ProductURL='+productLink"
              frameborder="0"
              onmousewheel=""
              width="100%"
              height="90%"
              style="background: transparent;">
      </iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderForm",
  props:      {
    productLink: String
  },
  methods:{
    onCloseClick(){
      console.info("onCloseClick")
      this.$emit('onCloseClick')
    }
  }
}
</script>

<style scoped>

</style>