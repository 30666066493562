<template>
  <div class="product">
    <div class="product__header">
      <div class="counter" v-if="countdown.length">
        <div class="counter__label">Предложение действует</div>
        <div class="counter__val">{{ countdown }}</div>
      </div>
      <div class="product__img">
        <!--<img src="https://upload.wikimedia.org/wikipedia/ru/6/6b/NyanCat.gif" alt="" />-->
        <img :src="offer.image" alt=""/>
        <!--<img src="https://static.standard.co.uk/2021/04/23/05/newFile.jpg?width=968&auto=webp&quality=75&crop=968%3A645%2Csmart" alt="" />-->
      </div>
      <ul class="product__badge">
        <li v-if="offer.brand">{{ offer.brand }}</li>
        <li class="product__badge_discount" v-if="offer.priceOld > offer.price">
          -{{ (offer.priceOld - offer.price).toFixed(2) }}₴
        </li>
      </ul>
    </div>
    <div class="product__body">
      <div class="product__title">{{ offer.title }}</div>
      <!--<div class="product__description">Женские кроссовки для города</div>-->
      <a :href="offer.link" target="_blank" class="product__link">Перейти в магазин</a>
      <!--<div class="product__date">27.10.2021</div>-->
      <!--<div class="product__date">{{offer.expired}}</div>-->
      <button class="product__button button" @click="onBuyClick">Оформить заказ</button>
      <div class="product__price">
        <div class="product__price_starting" v-if="offer.priceOld > offer.price">{{ offer.priceOld.toFixed(2) }}₴</div>
        <div class="product__price_current">{{ offer.price.toFixed(2) }}₴</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:  "OfferComponent",
  props: {
    offer: {
      title:    String,
      link:     String,
      brand:    String,
      image:    String,
      price:    Number,
      priceOld: Number,
      expired:  Number,
    },
  },
  data() {
    return {
      countdown: '',
    }
  },
  mounted() {
    console.info("OfferComponent", this.offer);
    console.info("exp", this.offer.expired, this.$refs.countdown);

    this.countDownInterval = setInterval(() => { //TODO clear interval on hide preloader
      let now      = Date.now();
      let distance = Math.max(0, Math.floor((this.offer.expired - now)));
      if (distance > 1000) {
        this.countdown = this.countDownText(distance / 1000);
        // this.$refs.countdown.textContent = this.countDownText(distance / 1000);
      } else {
        this.countdown = this.countDownText(0);
        clearInterval(this.countDownInterval);
      }
    }, 1001);
  },
  beforeUnmount() {
    clearInterval(this.countDownInterval);
  },
  methods: {
    countDownText(distance) { //distance in sec
      // console.info("countDownText", distance);
      // let days = this.formatTime(distance, 86400, 100);
      // let hours = this.formatTime(distance, 3600, 24);
      let hours   = this.formatTime(distance, 3600, 10000);
      let minutes = this.formatTime(distance, 60, 60);
      let seconds = this.formatTime(distance, 1, 60);

      // console.info("countDownText", hours, minutes, seconds);

      return hours + ":" + minutes + ":" + seconds;
    },
    formatTime(seconds, unit, modulo, minimumIntegerDigits = true) {
      let str = ((Math.floor(seconds / unit)) % modulo).toString();
      if ((str.length) < 2 && minimumIntegerDigits) {
        str = "0" + str;
      }
      return str
    },
    onBuyClick() {
      console.info("onBuyClick", this.offer.id);
      // window.location.href = 'https://assistant.shopocircles.com/?offer=' + this.offer.id;
      this.$emit('onBuy', this.offer.link)
    }
  }
}
</script>