<template>
  <div class="wrapper"><!--loading-->
    <header class="header" :class="{'fixed': isHeaderFixed}">
      <a href="" class="logo"></a>
      <nav class="menu" :class="{'opened': isNavOpened}">
        <span class="menu__item" @click="scrollTo('start')">О ShopO</span>
        <span class="menu__item" @click="scrollTo('benefits')">Преимущества </span>
        <span class="menu__item" @click="scrollTo('products')">Лучшие предложения</span>
<!--        <span class="menu__item" @click="scrollTo('join')">Присоединиться</span>-->
<!--        <a href="https://apps.apple.com/us/app/shopo-circles/id979734388?ls=1" class="app-button app-button__ios"></a>-->
<!--        <a href="https://play.google.com/store/apps/details?id=com.shopocircles" class="app-button app-button__android"></a>-->
      </nav>
      <div class="menu-toggle" @click="isNavOpened = !isNavOpened"></div>
    </header>
    <section class="start-section" ref="start">
      <div class="start-section__wrap">
        <div class="start-section__partners">
          <div class="start-section__partners_txt">Pазработано в ассоциации с</div>
          <img src="./assets/img/facebook.svg" class="start-section__partner" alt="">
          <img src="./assets/img/ups.svg" class="start-section__partner" alt="">
          <img src="./assets/img/western-union.svg" class="start-section__partner" alt="">
        </div>
        <h1>Брендовые товары из США и Европы по выгодным ценам</h1>
        <span class="button" @click="scrollTo('products')">Начать покупки</span>
        <span class="button button__border" @click="scrollTo('procedure')">Узнать больше</span>
      </div>
    </section>
    <section class="partners-section">
      <img class="partners-section__item"  src="./assets/img/stores/calvin-klein.svg"/>
      <img class="partners-section__item"  src="./assets/img/stores/vans.svg"/>
      <img class="partners-section__item"  src="./assets/img/stores/timberland.svg"/>
      <img class="partners-section__item"  src="./assets/img/stores/tommy-hilfiger.svg"/>
      <img class="partners-section__item"  src="./assets/img/stores/ralph-lauren.svg"/>
      <img class="partners-section__item"  src="./assets/img/stores/nyx.svg"/>

<!--      <object class="partners-section__item" type="image/svg+xml" data="./assets/img/stores/calvin-klein.svg"></object>
      <object class="partners-section__item" type="image/svg+xml" data="./assets/img/stores/vans.svg"></object>
      <object class="partners-section__item" type="image/svg+xml" data="./assets/img/stores/timberland.svg"></object>
      <object class="partners-section__item" type="image/svg+xml" data="./assets/img/stores/tommy-hilfiger.svg"></object>
      <object class="partners-section__item" type="image/svg+xml" data="./assets/img/stores/ralph-lauren.svg"></object>
      <object class="partners-section__item" type="image/svg+xml" data="./assets/img/stores/nyx.svg"></object>-->
    </section>
    <section class="statistic-section">
      <div class="statistic-section__item">
        <div class="statistic-section__title">>370&nbsp;000</div>
        <div class="statistic-section__info">доставленных товаров</div>
      </div>
      <div class="statistic-section__item">
        <div class="statistic-section__title">>17&nbsp;400</div>
        <div class="statistic-section__info">постоянных клиентов</div>
      </div>
      <div class="statistic-section__item">
        <div class="statistic-section__title">1195</div>
        <div class="statistic-section__info">шоппинг ассистентов</div>
      </div>
      <div class="statistic-section__item">
        <div class="statistic-section__title">>5&nbsp;лет</div>
        <div class="statistic-section__info">на рынке</div>
      </div>
    </section>
    <section class="benefits-section" ref="benefits">
      <h2>Почему выгодно покупать товары из США и Европы с ShopO?</h2>
      <div class="benefits-section__list">
        <div class="benefits-section__item">
          <p>Подлинная продукция и доступ к лучшим скидкам</p>
          <img class="benefits-section__img" type="image/svg+xml" src="./assets/img/benefits/benefit-1.svg"/>
        </div>
        <div class="benefits-section__item">
          <p>Выгодные тарифы на доставку</p>
          <img class="benefits-section__img" type="image/svg+xml" src="./assets/img/benefits/benefit-2.svg"/>
        </div>
        <div class="benefits-section__item">
          <p>Персональный подход и гарантия качества обслуживания</p>
          <img class="benefits-section__img" type="image/svg+xml" src="./assets/img/benefits/benefit-3.svg"/>
        </div>
      </div>
    </section>
    <section class="discount-section">
      <h2>Лучшие предложения</h2>
      <div class="discount-section__list">
        <div class="discount-section__item" @click="onFilterClick(0)">
          <div class="discount-section__img-wrap">
            <img class="discount-section__img" src="./assets/img/discounts/discount-1.jpg" alt="" />
          </div>
          <div class="discount-section__price" >до {{ priceRanges[0].max }}₴</div>
        </div>
        <div class="discount-section__item" @click="onFilterClick(1)">
          <div class="discount-section__img-wrap">
            <img class="discount-section__img" src="./assets/img/discounts/discount-2.jpg" alt="" />
          </div>
          <div class="discount-section__price" >{{ priceRanges[1].min }}-{{ priceRanges[1].max }}₴</div>
        </div>
        <div class="discount-section__item" @click="onFilterClick(2)">
          <div class="discount-section__img-wrap">
            <img class="discount-section__img" src="./assets/img/discounts/discount-3.jpg" alt="" />
          </div>
          <div class="discount-section__price" >{{ priceRanges[2].min }}-{{ priceRanges[2].max }}₴</div>
        </div>
        <div class="discount-section__item" @click="onFilterClick(3)">
          <div class="discount-section__img-wrap">
            <img class="discount-section__img" src="./assets/img/discounts/discount-4.jpg" alt="" />
          </div>
          <div class="discount-section__price" >более {{ priceRanges[3].min }}₴</div>
        </div>
      </div>
      <div class="button" @click="onFilterClick(null)">Смотреть все</div>
    </section>
    <section class="procedure-section" ref="procedure">
      <h2>Как выгодно покупать с ShopO?</h2>
      <div class="procedure-section__list">
        <div class="procedure-section__item">
          <strong class="procedure-section__title">Мировые бренды</strong>
          <span class="procedure-section__content">
            Выбирай выгодные предложения в online-магазинах США и Европы. Через ShopO можно покупать товары не только от известных мировых брендов, но и в маленьких магазинах, даже если в них не предусмотрена возможность прямой доставки в Украину.
          </span>
        </div>
        <div class="procedure-section__item">
          <strong class="procedure-section__title">Доставка товара</strong>
          <span class="procedure-section__content">
            ShopO доставляет товары консолидированным способом, благодаря чему стоимость доставки выходит на 70% дешевле. Оформление накладной и полный контроль доставки товара мы берем на себя!
          </span>
        </div>
        <div class="procedure-section__item">
          <strong class="procedure-section__title">Шоппинг ассистент</strong>
          <span class="procedure-section__content">
            Индивидуальный подход к каждому клиенту. Твой личный шоппинг ассистент поможет тебе определиться с выбором, оформит заказ и организует доставку. Тебе не нужно будет ни о чем волноваться!
          </span>
        </div>
        <div class="procedure-section__item">
          <strong class="procedure-section__title">Довольный клиент</strong>
          <span class="procedure-section__content">
            Совершай покупки через приложение ShopO и наслаждайся оригинальными брендовыми вещами из США и Европы по низким ценам. С ShopO делать покупки на 50% выгоднее. Присоединяйся!
          </span>
        </div>
      </div>
    </section>
    <section class="products-section" ref="products">
      <h2>Товары с лучшими ценами</h2>
      <form class="products-section__nav filter">
        <input type="radio" id="filter-all" name="filter-group" class="hidden" checked v-model="currentPrice" value="null">
        <label class="filter__item" for="filter-all">Все</label>

        <input type="radio" id="filter-price-1" name="filter-group" class="hidden" v-model="currentPrice" value="0">
        <label class="filter__item" for="filter-price-1">до {{ priceRanges[0].max }}₴</label>

<!--        <input type="radio" id="filter-price-2" name="filter-group" class="hidden" @change="onFilterClick(1)">-->
        <input type="radio" id="filter-price-2" name="filter-group" class="hidden" v-model="currentPrice" value="1">
        <label class="filter__item" for="filter-price-2">{{ priceRanges[1].min }}-{{ priceRanges[1].max }}₴</label>

        <input type="radio" id="filter-price-3" name="filter-group" class="hidden" v-model="currentPrice" value="2">
        <label class="filter__item" for="filter-price-3">{{ priceRanges[2].min }}-{{ priceRanges[2].max }}₴</label>

        <input type="radio" id="filter-price-4" name="filter-group" class="hidden" v-model="currentPrice" value="3">
        <label class="filter__item" for="filter-price-4">более {{ priceRanges[3].min }}₴</label>
      </form>
      <offers-list-component v-if="offersFilter"
          :filter="offersFilter"
          @buyOffer="onBuyOffer"
      ></offers-list-component>
    </section  >
    <section class="join-section" ref="join">
<!--      <h3>Присоединяйся к сообществу ShopO</h3>
      <p>Будь в тренде - покупай качественные вещи экономно</p>
      <a href="https://apps.apple.com/us/app/shopo-circles/id979734388?ls=1" class="join-section__button app-button app-button__ios"></a>
      <a href="https://play.google.com/store/apps/details?id=com.shopocircles" class="join-section__button app-button app-button__android"></a>-->
    </section>
    <OrderForm v-if="productLink" :product-link="productLink" @onCloseClick="onOrderFormClose"></OrderForm>
    <footer class="footer">
      <div class="container">
        <a href="" class="logo logo__white"></a>
        <div class="footer__copyright">(C) 2022 | ShopO Circle</div>
        <a href="https://shopocircles.com/about/privacy-policy" class="footer__nav">Privacy Policy</a>
      </div>
    </footer>
  </div>
  </template>

<script>

import './assets/css/style.less'
import OffersListComponent from "@/components/OffersListComponent";
import OrderForm from "@/components/OrderForm";

export default {
  name: 'App',
  components: {
    OrderForm,
    OffersListComponent,
  },
  data(){
    return {
      priceRanges : [
        {min:1, max:350},
        {min:350, max:1000},
        {min:1000, max:2000},
        {min:2000},
      ],
      currentPrice:null,
      currentStore:this.$route.query.store,
      offersFilter:null,
      isNavOpened: false,
      isHeaderFixed: false,
      productLink: null,
    }
  },
  watch:{
    currentPrice: function (){
      console.info("currentPrice change", this.currentPrice);
      let newFilter = {
        store: this.currentStore
      }
      let price = this.priceRanges[this.currentPrice];
      if (price){
        newFilter.minPrice = price.min;
        newFilter.maxPrice = price.max;
      }
      console.info("newFilter", newFilter);
      this.offersFilter=newFilter;
    }
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    console.info("app.vue mounted", this.$route.query.store);
    let search = new URLSearchParams(window.location.search);
    this.currentStore = search.get('store');
    this.offersFilter = {
      store:   this.currentStore,
    };
  },
  methods:{
    showForm(link=''){
      console.info("showForm", link)
      this.productLink=link;
    },
    onPlaceOrderCLick(){
      console.info("onPlaceOrderCLick")
      this.showForm(' ');
    },
    onBuyOffer(link){
      console.info("onOfferBuyClick", link)
      this.showForm(link)
    },
    onOrderFormClose(){
      console.info("onOrderFormClose")
      this.productLink=null;
    },
    onFilterClick(priceIndex){
      console.info("onFilterClick", priceIndex, this.currentPrice);
      this.currentPrice = priceIndex;
      this.scrollTo('products')
      // this.offersFilter.store='jma';
    },
    scrollTo(article){
      console.info("scrollTo", article);
      let el = this.$refs[article]
      if (el) {
        // Use el.scrollIntoView() to instantly scroll to the element
        el.scrollIntoView({behavior: 'smooth'});
      }
      this.isNavOpened = false;
    },
    handleScroll: function () {
      this.isHeaderFixed = window.scrollY >= 30 ? true : false
    },
    /*getOffers(filter={}){
      console.info("getOffers");
      filter.store='jma'
      filter.minPrice='2'
      filter.maxPrice='3'


     let filterFormula=[];
      if (filter.store){
        filterFormula.push(`FIND('${filter.store}', {Link})`)
      }
      if (filter.minPrice){
        filterFormula.push(`{Price} >= ${filter.minPrice}`)
      }
      if (filter.maxPrice){
        filterFormula.push(`{Price} <= ${filter.maxPrice}`)
      }
      console.info(`AND(${filterFormula.join(', ')})`)
      this.base('Offers').select({
        // Selecting the first 3 records in All offers:
        // maxRecords: 3,
        pageSize: 2,
        view: "All offers", //Current offers
        filterByFormula: `AND(${filterFormula.join(', ')})`
      }).eachPage(function page(records, fetchNextPage) {
        console.info("PAGE");
        // This function (`page`) will get called for each page of records.
        // console.info("eachPage", records, fetchNextPage)
        records.forEach(function(record) {
          console.log('record',record.get('offer_id'), record.get('Price'), record.get('Link'));
          // console.log('Retrieved', record.get('offer_id'));
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        fetchNextPage();

      }, function done(err) {
        console.info("done");
        if (err) { console.error(err); return; }
      });
    }*/
  }
}
</script>
