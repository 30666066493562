<template>
  <div class="products-section__list">
    <offer-component
        v-for="item in offersList"
        :key="item.id"
        :offer="item"
        @onBuy="onBuyOffer"
    >
    </offer-component>
  </div>
</template>

<script>
import OfferComponent from "@/components/OfferComponent";
import Airtable from "airtable";
export default {
  name: "OffersListComponent",
  components: {OfferComponent},
  props: {
    filter: {
      store:String,
      maxPrice:Number,
      minPrice:Number,
    },
  },
  data(){
    return {
      offersList : [],
    }
  },
  watch: {
    filter:function(){
      console.info("watch", "filter",this.filter);
      this.getOffers();
    }
  },
  mounted() {
    console.info("OffersListComponent");
    this.base = new Airtable({apiKey: 'keyYpBPGAtcwIG680'}).base('apptltk9op8SRImVt');
    this.getOffers();
    this.checkOffer();
  },
  methods:{
    onBuyOffer(link){
      console.info('onBuyOffer', link);
      this.$emit('buyOffer', link)
    },
    checkOffer(){
      let search = new URLSearchParams(window.location.search);
      let offerID = search.get('offer');
      // let offerID = 'recXbMkGkM39pleys'
      if (offerID){
        this.base('Offers').find(offerID, (err, record)=>{
          if (err) { console.error(err); return; }
          console.log('Retrieved', record.id);
          this.$emit('buyOffer', record.get('Link'))
        });
      }


    },
    getOffers(){
      //TODO exclude empty rows
      // this.filter.a='b';
      console.info("getOffers", this.filter);
      let filterFormula=[];
      if (this.filter.store){
        filterFormula.push(`FIND('${this.filter.store}', {Link})`)
      }
      if (this.filter.minPrice){
        filterFormula.push(`{UAH} >= ${this.filter.minPrice}`)
      }
      if (this.filter.maxPrice){
        filterFormula.push(`{UAH} <= ${this.filter.maxPrice}`)
      }
      console.info(`AND(${filterFormula.join(', ')})`)
      this.base('Offers').select({
        // Selecting the first 3 records in All offers:
        maxRecords: 50,
        // pageSize: 2,
        view: "Current offers", //Current offers
        filterByFormula: `AND(${filterFormula.join(', ')})`
      // }).eachPage((records, fetchNextPage)=>{
      }).eachPage((records, fetchNextPage)=>{
        console.info("PAGE", records);
        // This function (`page`) will get called for each page of records.
        // console.info("eachPage", records, fetchNextPage)
            this.offersList = [];
        records.forEach((record)=>{
          this.mapOfferData(record);
          // console.log('Retrieved', record.get('offer_id'));
        });

        // To fetch the next page of records, call `fetchNextPage`.
        // If there are more records, `page` will get called again.
        // If there are no more records, `done` will get called.
        this.loadNextPage = fetchNextPage;

      },
          function done(err) {
        console.info("done");
        if (err) { console.error(err); return; }
      });
    },

    mapOfferData(record){
      console.info("mapOfferData", record)
      this.offersList.push({
        id:record.id,
        offer_id:record.get('offer_id'),
        title:record.get('Title'),
        brand:record.get('Brand'),
        link:record.get('Link'),
        image:record.get('Images')[0]['url'],
        price:record.get('UAH'),
        priceOld:record.get('UAH_old'),
        expired: Date.parse(record.get('Expiration Date'))
      })
    },
    loadMoreOffers(){
      this.busy = true;
      this.loadNextPage();
    }
  }

}
</script>

<style scoped>

</style>